import React from "react"
import { MODAL_TYPES } from "~src/common/constants"
import { Button } from "~src/components/Button"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"

const StartChainlistSection = () => {
  const { handleModal } = useShowAuthModals()
  return (
    <div className="bg-[#E3EFFA]">
      <div className="h-[276px] flex flex-col items-center justify-center space-y-[35px] max-w-7xl mx-auto">
        <h1
          className="text-[#2C2C2C] text-xl lg:text-[32px] font-bold"
        >
          Start your own ChainList today
        </h1>
        <Button
          onClick={() => handleModal(MODAL_TYPES.signup)}
          className="border-[5px] text-xs lg:text-base"
        >
          Sign up
        </Button>
      </div>
    </div>
  )
}

export default StartChainlistSection
