import React, { useState } from "react"
import { TimelineDetail } from "~src/components/TimelineDetail"
import { useMobileDetect } from "~src/components/useMobileDetect"
import ViewItemArrow from "~src/components/Dashboard/dashboard-view-item-arrow/dashboard-view-item-arrow"

const TimelineSection = ({ occurrence }: any) => {
  const isMobile = useMobileDetect()
  const [hideCompletedItems, setHideCompletedItems] = useState(false)

  const [showCount, setShowCount] = useState(3)
  const loadMoreCount = 3

  const handleLoadMore = () => {
    setShowCount((prevCount) => prevCount + loadMoreCount)
  }

  return (
    <div className="px-6 xl:px-0 py-[89px] max-w-7xl mx-auto">
      <div className="mb-[67px]">
        <h1
          className="text-gradient text-2xl text-center md:text-4xl xl:text-5xl lg:text-left"
        >
          Your personal ChainList starts here.
        </h1>
        <div
          className="text-[#2C2C2C] text-sm lg:text-base text-center lg:text-left" 
        >
          <p>
            Take a look at an example of a <i className="font-semibold">ChainList</i> - a combined list of
            all the checklists you use.
          </p>
          <p>
            The days of keeping up with dozens of lists are over - one ChainList
            is all you need.
          </p>
        </div>
      </div>
      {occurrence
        ?.slice(0, showCount)
        .map((o: { occurances: any; _id: Date }, i: React.Key) => {
          return (
            <div className="lg:w-3/4 w-full" key={i}>
              <TimelineDetail
                checklist={o.occurances[0].checklist}
                hideCompletedItems={hideCompletedItems}
                occurance={o?.occurances}
                occuranceDate={o?._id}
                runs={occurrence?.runs || []}
                showChecklist={true}
                disableCheckbox={true}
              />
            </div>
          )
        })}
      {occurrence?.length > showCount && (
        <div
          className={`z-50 relative ${
            isMobile ? "pl-0 w-full" : "pl-40 w-8"
          } h-8 flex items-center justify-center`}
        >
          <ViewItemArrow
            arrowType="next"
            label="load more items"
            onClick={handleLoadMore}
          />
          <div className="lg:hidden block h-px w-full bottom-4 bg-gray4 absolute" />
        </div>
      )}
    </div>
  )
}

export default TimelineSection
