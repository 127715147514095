import React from "react"
import { GridView } from "~src/components/Community/grid-view"

import { Link } from "~src/components/Link"
import { routes } from "~src/routes"
import { useMobileDetect } from "~src/components/useMobileDetect"

const renderGridItems = (
  start: any,
  end: any,
  mtClass: any,
  checklists: any
) => {
  const gridItems = []
  const checklistsLength = checklists?.length
  for (let i = start; i <= end && i < checklistsLength; i++) {
    gridItems.push(
      <div key={i} className={`h-[175px] max-w-full rounded-lg ${mtClass}`}>
        <GridView data={checklists[i]} />
      </div>
    )
  }
  return gridItems
}

export default function CommunityChecklistsSection({
  checklists,
}: any): JSX.Element {
  const isMobile = useMobileDetect()

  return (
    <div className="bg-white">
      <div className="pt-28 pb-8 max-w-screen-2xl relative mx-auto">
        <div className="mb-32 flex flex-col items-center justify-center text-center px-2 md:px-16 xl:px-[120px]">
          <h1 className="text-gradient text-2xl md:text-4xl xl:text-5xl">
            Created by experts, for you
          </h1>
          <p className={`${isMobile ? "text-sm" : "text-base"}`}>
            Here are some of the great checklists that are shared to our <br />
            <Link
              href={routes.generic.community()}
              className="text-primaryBlue font-bold"
            >
              Community
            </Link>
            . Use them, copy them, make them yours.
          </p>
        </div>

        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
          <div className="grid max-sm:gap-16 md:gap-0 lg:gap-0">
            {renderGridItems(0, 2, "", checklists)}
          </div>
          <div className="max-sm:hidden md:grid lg:grid gap-8">
            {renderGridItems(3, 5, "mt-12", checklists)}
          </div>
          <div className="max-sm:hidden md:hidden lg:grid">
            {renderGridItems(6, 8, "", checklists)}
          </div>
          <div className="max-sm:hidden md:hidden lg:hidden xl:grid gap-8">
            {renderGridItems(9, 11, "mt-12", checklists)}
          </div>
          <div className="max-sm:hidden md:hidden lg:hidden xl:grid">
            {renderGridItems(12, 14, "", checklists)}
          </div>
        </div>

        <div className="absolute inset-x-0 bottom-12 md:bottom-48 h-48 bg-gradient-to-t from-white to-transparent"></div>
      </div>
    </div>
  )
}
