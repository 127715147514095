import React, { useCallback } from "react"
import { Button } from "~src/components/Button"
import { Link } from "~src/components/Link"
import { Logo } from "~src/components/Logo"
import { routes } from "~src/routes"
import addList from "~src/../public/icons/add-list.svg"
import { SearchBox } from "~src/components/SearchBox"
import { useStore } from "~src/store"
import { Tooltip } from "~src/components/Tooltip"
import { IconButton } from "~src/components/IconButton"
import { SearchProvider } from "~src/components/SearchProvider"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { MODAL_TYPES } from "~src/common/constants"
import { useMobileDetect } from "~src/components/useMobileDetect"
import { Container } from "~src/components/Container"

interface HeaderProps {
  isAuthenticated: boolean
}

const HeaderSection = ({ isAuthenticated }: HeaderProps): JSX.Element => {
  const [showSearch, setShowSearch] = React.useState<boolean>(false)
  const { handleModal } = useShowAuthModals()
  const isMobile = useMobileDetect()

  const {
    actions: {
      system: { isSearchResultOpened },
    },
  } = useStore()

  const handleCloseSearchbox = useCallback(() => {
    isSearchResultOpened({ isSearchResultOpened: false })
    setShowSearch(false)
  }, [isSearchResultOpened])

  return (
    <SearchProvider>
      <nav className="bg-white">
        <Container
          className="z-20 flex items-center justify-between w-full px-2 py-4 mx-auto"
          id="main-wrapper"
          width="7xl"
        >
          <div>
            <Link
              href={
                isAuthenticated
                  ? routes.generic.dashboardView()
                  : routes.generic.dashboard()
              }
              unstyled
            >
              <div className="flex flex-row items-center">
                <Logo className="w-24 md:w-32" />
              </div>
            </Link>
          </div>
          <div className={`flex ${isMobile ? "" : "space-x-6"}`}>
            <div className={`flex items-center ${isMobile ? "" : "space-x-4"}`}>
              <div
                className={`flex items-center ml-auto ${
                  showSearch && "flex-1 sm:flex-initial"
                }`}
              >
                {!isMobile && showSearch && (
                  <div className="flex flex-1">
                    <SearchBox onClose={handleCloseSearchbox} />
                  </div>
                )}
                <div>
                  {!showSearch && (
                    <Tooltip tooltipTitle={"Search your lists and all lists"}>
                      <span>
                        <IconButton
                          className={"intro-search-icon cursor-default"}
                          icon="Search"
                          iconStyles={`${isMobile ? "" : "w-6 h-6"}`}
                          onClick={() => setShowSearch(true)}
                          tooltip="hello"
                          variant="link"
                          id="search"
                        />
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div>
                <div className="intro-new-checklist-hint intro-create-checklist">
                  <Button
                    href={routes.checklists.new()}
                    icon
                    style={{
                      width: 40,
                      height: 36,
                    }}
                    tooltip="Create new template"
                    variant="link"
                  >
                    <img
                      alt="add list"
                      className={`${isMobile ? "" : "w-6 h-6"}`}
                      src={addList}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div
              className={`flex items-center justify-between border-l ${
                isMobile ? "" : "space-x-4"
              }`}
            >
              <Button
                className={`btn--link font-bold text-sm ${
                  isMobile ? "text-xs" : ""
                }`}
                onClick={() => handleModal(MODAL_TYPES.login)}
              >
                Login
              </Button>
              <Button
                className={`text-[15px] ${isMobile ? "text-xs" : ""}`}
                onClick={() => handleModal(MODAL_TYPES.signup)}
              >
                Sign up
              </Button>
            </div>
          </div>
        </Container>
      </nav>
      {isMobile && showSearch && (
        <div className="px-6 bg-white">
          <SearchBox onClose={handleCloseSearchbox} showSearchClose={true} />
        </div>
      )}
    </SearchProvider>
  )
}

export default HeaderSection
