import Prismic from "@prismicio/client"
import ApiSearchResponse from "@prismicio/client/types/ApiSearchResponse"
import { NextPageContext } from "next"
import { useRouter } from "next/router"
import React from "react"
// import { errorReporter } from "~src/common/lib"
import { LandingPage } from "~src/components"
import NewLandingPage from "~src/components/NewLandingPage/NewLandingPage"
import { routes } from "~src/routes"
// import { AuthService } from "~src/services"
import { getLoggedUserFromHeaders } from "~src/store/checklist/actions/utils"
import {
  ChecklistService,
  IChecklistParams,
  ScheduleService,
} from "~src/store/checklist/effects"
export default function Home(props: {
  response: ApiSearchResponse
  checklists: Checklist[]
  occurrence?: any
}): JSX.Element {
  const router = useRouter()
  // const [response, setResponse] = React.useState<ApiSearchResponse>()
  // const [loading, setLoading] = useState<boolean>(true)

  const data = props?.response?.results[0]?.data || {}

  const pagetitle = data?.pagetitle || {}

  const text = pagetitle?.[0]?.text || "Chainlist"

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const apiEndpoint = process.env.NEXT_PUBLIC_PRISMIC_PRODUCT_TOUR_URL
  //     const apiToken = process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN

  //     const client = Prismic.client(apiEndpoint, { accessToken: apiToken })

  //     const res = await client.query(
  //       Prismic.Predicates.at("document.type", "landing")
  //     )

  //     const headers = {
  //       Authorization: AuthService.getAuthTokenClient(),
  //     }
  //     const userAuth = await AuthService.getCurrentUser(headers)
  //     if (userAuth) {
  //       setResponse(res)
  //       router.push(routes.generic.dashboardView())
  //     } else {
  //       setResponse(res)
  //       router.push(routes.generic.community())
  //     }
  //   }
  //   setLoading(true)
  //   fetchData().catch((err) => errorReporter.notify(err))
  // }, [router])

  // if (loading) {
  //   return (
  //     <div className=" h-screen w-full flex justify-center items-center">
  //       <Loading />
  //     </div>
  //   )
  // }

  return (
    <>
      <meta title={text}></meta>
      <NewLandingPage {...props} />
    </>
  )
}

export async function getServerSideProps(ctx: NextPageContext) {
  try {
    const apiEndpoint = process.env.NEXT_PUBLIC_PRISMIC_PRODUCT_TOUR_URL
    const apiToken = process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN

    const client = Prismic.client(apiEndpoint, { accessToken: apiToken })

    const response = await client.query(
      Prismic.Predicates.at("document.type", "landing")
    )
    const params: IChecklistParams = {
      limit: 15,
      verifiedOnly: true,
    }
    const checklists = await ChecklistService.all(params)
    const occurrence = await ScheduleService.getScheduleChecklistItems()
    const auth = await getLoggedUserFromHeaders(ctx.req)
    ctx.res.setHeader("Cache-Control", "no-store")
    if (auth) {
      return {
        props: {
          response,
          checklists,
        },
        redirect: {
          destination: routes.generic.dashboardView(),
          permanent: true,
        },
      }
    }
    return {
      props: {
        response,
        checklists,
        occurrence,
      },
    }
  } catch {
    return { props: {} }
  }
}
