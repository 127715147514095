import React, { useState } from "react"
import { inputClassNames } from "~src/common/lib"
import { Button } from "~src/components/Button"
import sparklesIcon from "~public/icons/sparkles.svg"
import { useMobileDetect } from "~src/components/useMobileDetect"
import { routes } from "~src/routes"
import { useRouter } from "next/router"

const AiGenerateSection = () => {
  const inputClasses = inputClassNames({})
  const isMobile = useMobileDetect()
  const router = useRouter()
  const [inputValue, setInputValue] = useState<string>()

  return (
    <div className="bg-[#F8FAFC] pt-32 top-[-120px] md:top-[-200px] z-10 relative">
      <div
        className={`flex flex-col justify-center lg:justify-between lg:flex-row items-center max-w-7xl mx-auto px-6 xl:px-0`}
      >
        <div>
          <div className="bg-[#8CCD65] text-xs font-bold rounded w-[35px] h-[18px] text-white text-center mx-auto lg:mx-0">
            NEW
          </div>
          <div>
            <h4 className="text-[#2C2C2C] font-bold text-center lg:text-left sm:text-[20px] xl:text-[32px]">
              Can't find that one checklist
              <br /> you're looking for?
            </h4>
            <h2
              className="text-gradient
               text-center xl:text-left text-2xl mb-8 md:text-4xl xl:text-5xl
            "
            >
              Let AI write it for you.
            </h2>
          </div>
        </div>
        <div className="bg-white w-[320px] py-6 md:w-[475px] md:h-[177px] flex flex-col items-center justify-center">
          <div>
            <h4 className="font-bold text-sm mb-[8px] text-center lg:text-left">
              Title of your checklist
            </h4>
            <input
              className={`${inputClasses} h-11 ${
                isMobile ? "mb-4" : "w-[427px]"
              }`}
              id="title"
              placeholder="'Personal finance', for example"
              onChange={(e) => setInputValue(e.target.value)}
            />
            <div
              className={`bg-[#EDF8FE] mx-auto lg:mx-0 ${
                isMobile ? "w-36" : "w-[162px]"
              }`}
            >
              <Button
                variant="link"
                className={`${
                  isMobile ? "text-center text-xs" : ""
                } text-primaryBlue px-1 text-[13px] mt-6 rounded-sm`}
                onClick={() =>
                  router.push(routes.generic.aiChecklist(inputValue))
                }
              >
                <img alt="sparkles" className="mr-2" src={sparklesIcon} />
                AI generate checklist
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AiGenerateSection
