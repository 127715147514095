import React from "react"
import { useState } from "~src/store"
import { LoginModal } from "../Login"
import { SignupModal } from "../Signup"
import TimelineSection from "./components/TimelineSection/TimelineSection"
import { BaseLayout } from "~src/layouts"
import HeaderSection from "./components/HeaderSection/HeaderSection"
import HeroSection from "./components/HeroSection/HeroSection"
import CommunityChecklistsSection from "./components/CommunityChecklistsSection/CommunityChecklistsSection"
import AiGenerateSection from "./components/AiGenerateSection/AiGenerateSection"
import StartChainlistSection from "./components/StartChainlistSection/StartChainlistSection"
import FooterSection from "./components/FooterSection/FooterSection"

const NewLandingPage = (props: any): JSX.Element => {
  const { checklists, occurrence } = props
  const {
    auth: { showLoginModal, showSignupModal, state },
  } = useState()

  const isAuthenticated = Boolean(props?.auth || state === "authenticated")
  return (
    <BaseLayout>
        <HeaderSection isAuthenticated={isAuthenticated} />
        <HeroSection />
        <TimelineSection occurrence={occurrence} />
        <CommunityChecklistsSection checklists={checklists} />
        <AiGenerateSection />
        <StartChainlistSection />
        <FooterSection />
        {showLoginModal && <LoginModal />}
        {showSignupModal && <SignupModal />}
    </BaseLayout>
  )
}

export default NewLandingPage
