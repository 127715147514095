import Link from "next/link"
import React from "react"
import { MODAL_TYPES } from "~src/common/constants"
import { useMobileDetect } from "~src/components/useMobileDetect"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { routes } from "~src/routes"

const FooterSection = () => {
  const isMobile = useMobileDetect()
  const { handleModal } = useShowAuthModals()
  return (
    <div className="bg-[#2C2C2C]">
      <div className="py-[79px] max-w-7xl mx-auto">
        <div className="grid grid-cols-12">
          <div
            className={`${
              isMobile ? "text-center mb-6 col-span-12" : "col-start-2 col-span-4"
            }`}
          >
            <h4 className="text-white font-bold text-3xl lg:text-[38px]">
              ChainList
            </h4>
          </div>
          <div
            className={`${
              isMobile ? "col-span-12 text-center mb-6" : "col-span-2"
            }`}
          >
            <ul>
              <li className="text-[#ACACAC] text-xl lg:text-[23px] font-bold mb-[22px]">
                Getting started
              </li>
              <li className="text-white text-sm lg:text-[15px] font-bold mb-6">
                <Link href={routes.checklists.new()}>Create a checklist</Link>
              </li>
              <li className="text-white text-sm lg:text-[15px] font-bold mb-6">
                <Link href={routes.generic.community()}>Find checklists</Link>
              </li>
              <li
                onClick={() => handleModal(MODAL_TYPES.signup)}
                className="text-white text-sm lg:text-[15px] font-bold cursor-pointer"
              >
                Sign up
              </li>
            </ul>
          </div>
          <div
            className={`${
              isMobile ? "col-span-12 text-center mb-24" : "col-span-2"
            }`}
          >
            <ul>
              <li className="text-[#ACACAC] text-xl lg:text-[23px] font-bold mb-[22px]">
                About us
              </li>
              <li className="text-white text-sm lg:text-[15px] font-bold">
                Contact
              </li>
            </ul>
          </div>
          <div
            className={`${isMobile ? "col-span-12 text-center " : "col-span-1"}`}
          >
            <div className={`flex items-center justify-center space-x-4`}>
              <img
                src="/x-twitter.png"
                alt="twitter-logo"
                className="w-6 lg:w-8"
              />
              <img
                src="/facebook.png"
                alt="facebook-logo"
                className="w-6 lg:w-8"
              />
              <img
                src="/instagram.png"
                alt="instagram-logo"
                className="w-6 lg:w-8"
              />
              <img src="/tiktok.png" alt="tiktok-logo" className="w-6 lg:w-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterSection
