import React from "react"
import { Link } from "~src/components/Link"
import { useMobileDetect } from "~src/components/useMobileDetect"
import { routes } from "~src/routes"

const HeroSection = () => {
  const isMobile = useMobileDetect()
  return (
    <div className="bg-white">
      <div className="pb-32 max-w-7xl mx-auto">
        <div className="flex flex-col items-center pt-[80px]">
          <h1 className="text-[#2C2C2C] font-bold text-2xl text-center md:text-4xl xl:text-5xl">
            Checklists on <span className="text-gradient">autopilot</span>.
          </h1>
          <p className={`text-[#4F4F4F] ${isMobile ? "text-sm" : ""}`}>
            Know <span className="font-bold">what to do</span> and{" "}
            <span className="font-bold">when to do it.</span>
          </p>
        </div>
        <div className="flex items-center justify-between flex-col lg:flex-row mt-[20px] lg:mt-[94px] space-y-8 sm:space-y-0">
          <div className="flex flex-col items-center mb-[20px] md:mb-0 px-0 xl:px-4 w-[330px]">
            <div className="w-[240px] h-[240px] flex items-center justify-center mb-[20px] md:mb-0">
              <div>
                <img src="/checklist-create-subs.svg"/>
              </div>
            </div>
            <div className="flex items-start justify-between space-x-2">
              <div className="text-white text-[13px] bg-[#4294DD] font-bold py-[2px] px-[6px]">
                1
              </div>
              <p className={`text-[13px] font-bold ${isMobile ? "text-sm" : ""}`}>
                Create a checklist - or subscribe to one
                <br />
                <span className="font-medium">
                  (like any checklist on our{" "}
                  <Link href={routes.generic.community()} className="text-[13px]">
                    Community
                  </Link>{" "}
                  page!)
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center mb-[20px] md:mb-0 px-0 xl:px-4 w-[330px]">
            <div className="w-[240px] h-[240px] flex items-center justify-center mb-[20px] md:mb-0">
              <div>
                <img src="/checklist-track.svg"/>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="text-white text-[13px] bg-[#4294DD] font-bold py-[2px] px-[6px]">
                2
              </div>
              <p className={`text-[13px] font-bold ${isMobile ? "text-sm" : ""}`}>
                Keep track of all your to-do items
                <br /> in your personal ChainList
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center px-0 xl:px-4 w-[330px]">
            <div className="w-[240px] h-[240px] flex items-center justify-center mb-[20px] md:mb-0">
              <div>
                <img src="/checklist-notified.svg"/>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="text-white text-[13px] bg-[#4294DD] font-bold py-[2px] px-[6px]">
                3
              </div>
              <p className={`text-[13px] font-bold ${isMobile ? "text-sm" : ""}`}>
                Get notified when it's
                <br /> time to do something
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
